import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { LoadingButtonComponent } from './loading-button/loading-button.component';
import { MapStatsArtistComponent } from './map-stats/map-stats-artist.component';
import { MapStatsComponent } from './map-stats/map-stats.component';
import { MapComponent } from './map/map.component';
import { PagerComponent } from './pager/pager.component';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    PagerComponent,
    LoadingButtonComponent,
    MapComponent,
    MapStatsComponent,
    MapStatsArtistComponent,
  ],
  imports: [CommonModule],
  exports: [
    BreadcrumbComponent,
    PagerComponent,
    LoadingButtonComponent,
    MapComponent,
    MapStatsComponent,
    MapStatsArtistComponent,
  ],
})
export class SharedModule {}
