import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ApiOutputInterface } from '../../core/interfaces/api.interface';
import { FileInterface } from '../../core/services/file.service';
import { GenreInterface } from '../songs/genre.service';
import { RoleInterface } from './role.service';

export interface ArtistInterface {
  uuid: string;
  email: string;
  pseudo: string;
  avatar?: FileInterface;
  banner?: FileInterface;
  short_bio: string;
  genre?: GenreInterface;
  role?: RoleInterface;
  location?: string;
  location_lat?: number;
  location_long?: number;

  website: string;
  social_youtube: string;
  social_instagram: string;
  social_discord: string;
  social_spotify: string;
  social_deezer: string;
  social_soundcloud: string;
  social_twitter: string;
  social_tidal: string;
  social_itunes: string;
  social_bandcamp: string;

  is_visible: boolean;
  show_email: boolean;
  power: number;
  created_at: number;
}

export interface ArtistHttpInterface extends ApiOutputInterface {
  artist: ArtistInterface;
}

export interface ArtistsHttpInterface extends ApiOutputInterface {
  artists: Array<ArtistInterface>;
  pages: number;
}

@Injectable({
  providedIn: 'root',
})
export class ArtistService {
  constructor(private http: HttpClient) {}

  public list(
    page: number = 1,
    sort: string = 'DDESC',
    role: string = 'all',
    filter: string = '',
    restrict_visible: boolean = false
  ): Promise<ArtistsHttpInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .get<ArtistsHttpInterface>(environment.api + '/artists', {
          params: {
            page: page.toString(),
            sort: sort,
            filter: filter,
            role: role,
            restrict_visible: restrict_visible,
          },
        })
        .subscribe(
          (output: ArtistsHttpInterface) => {
            return resolve(output);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  public all(): Promise<Array<ArtistInterface>> {
    return new Promise((resolve, reject) => {
      this.http
        .get<ArtistsHttpInterface>(environment.api + '/artists/all')
        .subscribe(
          (output: ArtistsHttpInterface) => {
            return resolve(output.artists);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  public fetch(uuid: string): Promise<ArtistInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .get<ArtistHttpInterface>(environment.api + '/artists/' + uuid)
        .subscribe(
          (output: ArtistHttpInterface) => {
            return resolve(output.artist);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  public create(artist: ArtistInterface): Promise<ArtistInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .post<ArtistHttpInterface>(environment.api + '/artists/new', artist)
        .subscribe(
          (output: ArtistHttpInterface) => {
            return resolve(output.artist);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
  public edit(uuid: string, artist: ArtistInterface): Promise<ArtistInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .post<ArtistHttpInterface>(
          environment.api + '/artists/' + uuid + '/edit',
          artist
        )
        .subscribe(
          (output: ArtistHttpInterface) => {
            return resolve(output.artist);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
  public password(uuid: string, password: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http
        .post<ArtistHttpInterface>(
          environment.api + '/artists/' + uuid + '/password',
          {
            password: password,
          }
        )
        .subscribe(
          (output: ArtistHttpInterface) => {
            return resolve(true);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
  public power(uuid: string, power: number): Promise<ArtistInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .post<ArtistHttpInterface>(
          environment.api + '/artists/' + uuid + '/power',
          {
            power: power,
          }
        )
        .subscribe(
          (output: ArtistHttpInterface) => {
            return resolve(output.artist);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
  public visibility(uuid: string, visible: boolean): Promise<ArtistInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .post<ArtistHttpInterface>(
          environment.api + '/artists/' + uuid + '/visibility',
          {
            visibility: visible,
          }
        )
        .subscribe(
          (output: ArtistHttpInterface) => {
            return resolve(output.artist);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
  public delete(uuid: string): Promise<ArtistInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .delete<ArtistHttpInterface>(environment.api + '/artists/' + uuid)
        .subscribe(
          (output: ArtistHttpInterface) => {
            return resolve(output.artist);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
}
