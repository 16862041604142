import { SharedModule } from 'src/app/shared/shared.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClarityModule } from '@clr/angular';

import { DownloadButtonComponent } from './components/download-button/download-button.component';
import { GenresListComponent } from './genres/genres.component';
import { PlaylistsListComponent } from './playlists/playlists.component';
import { PlaylistslistSongsComponent } from './shared/playlistslist-songs/playlistslist-songs.component';
import { SongsListShowcaseButtonComponent } from './shared/songslist-showcase-button';
import { SongsVisibilityButtonComponent } from './shared/songslist-visibility.component';
import { SongsRoutingModule } from './songs-routing.module';
import { SongsListComponent } from './songslist/songslist.component';

@NgModule({
  declarations: [
    GenresListComponent,
    SongsListComponent,
    // PlaylistsListComponent,
    SongsListShowcaseButtonComponent,
    SongsVisibilityButtonComponent,
    DownloadButtonComponent,
    PlaylistsListComponent,
    PlaylistslistSongsComponent,
    // PlaylistsVisibilityButtonComponent,
    // PlaylistsSongsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    ClarityModule,
    SongsRoutingModule,
    FormsModule,
  ],
})
export class SongsModule {}
