import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './components/login/login.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'tracks',
    loadChildren: () =>
      import('./pages/songs/songs.module').then((m) => m.SongsModule),
  },
  {
    path: 'directory',
    loadChildren: () =>
      import('./pages/users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'dashboards',
    loadChildren: () =>
      import('./pages/dashboards/dashboards.module').then(
        (m) => m.DashboardsModule
      ),
  },
  { path: '*', redirectTo: '/dashboards', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
