import { AlertService } from 'src/app/core/services/alert.service';

import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.sass'],
})
export class AlertsComponent {
  @Input() pool: string = 'standard';

  constructor(public alert_service: AlertService) {}

  ngOnInit() {}

  get() {
    return this.alert_service.getAlerts(this.pool);
  }

  generateIcon(type: string): string {
    return (
      '<cds-icon class="alert-icon" shape="' +
      this.alert_service.getIcon(type) +
      '"></cds-icon>'
    );
  }
}
