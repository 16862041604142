<div class="content-area">
  <div class="clr-row clr-justify-content-center">
    <div class="clr-col-8">
      <div class="head">
        <ui-breadcrumb
          title="Gestion des genres"
          parent="Sons"
          parentUrl="songs/list"
        ></ui-breadcrumb>

        <div class="btns">
          <button (click)="lgOpen = true" class="btn btn-primary">
            <i class="fa fa-plus"></i> Ajouter
          </button>
        </div>
      </div>

      <table class="table">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="loading">
            <td colspan="2">Chargement en cours...</td>
          </tr>
          <tr *ngIf="genres.length < 1 && !loading">
            <td colspan="2">Aucun genre trouvé...</td>
          </tr>

          <ng-container *ngIf="!loading">
            <tr *ngFor="let genre of genres; let i = index">
              <td>
                {{ genre.name }}
              </td>
              <td>
                <div class="btn-group" role="group">
                  <button
                    class="btn btn-primary btn-icon"
                    (click)="edit(genre)"
                  >
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button
                    class="btn btn-danger btn-icon"
                    (click)="confirmDeletion(genre.uuid)"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>

<clr-modal [(clrModalOpen)]="lgOpen">
  <h3 class="modal-title">Nouveau genre musical</h3>
  <div class="modal-body">
    <form clrForm>
      <clr-input-container>
        <label class="clr-required-mark clr-col-12 clr-col-md-2">Nom</label>
        <input
          class="clr-col-12 clr-col-md-10"
          clrInput
          type="text"
          [(ngModel)]="genre.name"
          name="name"
        />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="lgOpen = false">
      Annuler
    </button>
    <button type="button" class="btn btn-primary" (click)="doFinish()">
      Sauvegarder
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="dlOpen">
  <h3 class="modal-title">Confirmer la suppression</h3>
  <div class="modal-body">
    <p>
      Etes-vous sûr de vouloir supprimer ce genre musical ?
      <strong>Vous ne pourrez pas le récupérer par la suite !</strong>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="dlOpen = false">
      Annuler
    </button>
    <button type="button" class="btn btn-primary" (click)="delete()">
      Confirmer
    </button>
  </div>
</clr-modal>
