import { AlertService } from 'src/app/core/services/alert.service';
import { environment } from 'src/environments/environment';

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ClrWizard } from '@clr/angular';

import {
  FileInterface,
  FileService,
} from '../../../core/services/file.service';
import { SEOService } from '../../../core/services/seo.service';
import { GenreInterface, GenreService } from '../genre.service';
import { MediaInterface, MediaService } from '../media.service';

@Component({
  selector: 'app-songslist',
  templateUrl: './songslist.component.html',
  styleUrls: ['./songslist.component.sass'],
})
export class SongsListComponent implements OnInit {
  // Common part
  moderation_mode = false;
  loading = false;

  // Listing part
  genres: Array<GenreInterface> = [];
  medium: Array<MediaInterface> = [];
  sort = 'DDESC';
  genre = 'all';
  page = 1;
  pageMax = 1;

  // Edition part
  @ViewChild('wizardlg') wizardLarge: ClrWizard | undefined;
  @ViewChild('cover') cover!: ElementRef;
  lgOpen: boolean = false;
  media = {} as MediaInterface;

  // Deletion part
  dlOpen: boolean = false;
  toDelete: string | null = null;

  constructor(
    private service: MediaService,
    private notifier: AlertService,
    public fileService: FileService,
    private genreService: GenreService,
    private router: Router,
    public seo: SEOService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.moderation_mode = this.router.url.indexOf('moderation') >= 0;

    // SEO edition
    this.seo.updateTitle('Liste des tubes');
    this.seo.injectCanonical();

    Promise.all([this.genreService.list(), this.update()]).then(
      ([genres]) => {
        this.genres = genres;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  on_next(): void {
    this.page += 1;
    this.update();
  }
  on_previous(): void {
    this.page -= 1;
    this.update();
  }

  edit(media: MediaInterface): void {
    this.media = JSON.parse(JSON.stringify(media));
    this.lgOpen = true;
  }

  confirmDeletion(uuid: string): void {
    this.toDelete = uuid;
    this.dlOpen = true;
  }

  setOrder(order: string): void {
    this.sort = order;
    this.update();
  }
  getOrder(): string {
    return this.sort;
  }

  setCategory(uuid = 'all'): void {
    if (this.genre == uuid) {
      this.genre = 'all';
    } else {
      this.genre = uuid;
    }
    this.update();
  }
  getCategory(): string {
    return this.genre;
  }

  delete(): void {
    if (this.toDelete)
      this.service.delete(this.toDelete).then(() => {
        this.toDelete = null;
        this.update();
        this.dlOpen = false;
        this.notifier.success('Son supprimé avec succès');
      });
  }

  getImageLink(uuid: string): string {
    return environment.api + '/images/' + uuid;
  }
  getGenres(media: MediaInterface): Array<GenreInterface> {
    return media.genres as Array<GenreInterface>;
  }
  getCover(media: MediaInterface): string {
    return (media.cover as FileInterface).uuid as string;
  }

  onShowcaseChange(media: MediaInterface) {
    for (let index = 0; index < this.medium.length; index++) {
      if (this.moderation_mode) {
        if (
          this.medium[index].uuid != media.uuid &&
          this.medium[index].owner.uuid == media.owner.uuid
        ) {
          this.medium[index].is_showcased = false;
        }
      } else {
        if (this.medium[index].uuid != media.uuid) {
          this.medium[index].is_showcased = false;
        }
      }
    }
  }

  update(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.service
        .list(this.page, this.sort, this.genre, this.moderation_mode)
        .then((output) => {
          this.medium = output.medium;
          this.pageMax = output.pages;
          this.loading = false;
          return resolve();
        });
    });
  }

  uploadCover($event: Event): void {
    $event.preventDefault();
    if (this.cover.nativeElement.files && this.cover.nativeElement.files[0]) {
      this.loading = true;
      this.fileService.upload(this.cover.nativeElement.files[0], 'cover').then(
        (image) => {
          this.media.cover = image;
          this.loading = false;
        },
        (err: Error) => {
          this.loading = false;
        }
      );
    }
  }

  toggleGenre(genre: GenreInterface): void {
    if (this.media && !this.media.genres) this.media.genres = [];

    let i = 0;
    let found = false;
    while (i < this.media.genres.length && !found) {
      if ((this.media.genres[i] as GenreInterface).uuid === genre.uuid) {
        found = true;
      } else {
        i++;
      }
    }
    if (found) {
      this.media.genres.splice(i, 1);
    } else {
      (this.media.genres as Array<GenreInterface>).push(genre);
    }
  }

  removeCover($event: Event): void {
    $event.preventDefault();
    this.media.cover = '';
  }

  isChecked(uuid: string): boolean {
    if (this.media && !this.media.genres) this.media.genres = [];

    let found = false;
    let i = 0;
    while (!found && i < this.media.genres.length) {
      if ((this.media.genres[i] as GenreInterface).uuid === uuid) {
        found = true;
      } else {
        i++;
      }
    }
    return found;
  }

  doCancel(): void {
    this.media = {} as MediaInterface;
    if (this.wizardLarge) this.wizardLarge.reset();
  }

  doFinish(): void {
    // $event.preventDefault();
    this.loading = true;

    let promise: any;
    if (this.media.uuid) {
      promise = this.service.edit(this.media.uuid, this.media);
    } else {
      promise = this.service.create(this.media);
    }

    promise.then(
      () => {
        this.loading = false;
        this.lgOpen = false;

        if (this.media.uuid) {
          this.notifier.success('Son édité avec succès !');
        } else {
          this.notifier.success('Son ajouté avec succès !');
        }

        this.media = {} as MediaInterface;
        if (this.wizardLarge) this.wizardLarge.reset();
        this.update();
      },
      (err: Error) => {
        this.loading = false;
        this.lgOpen = false;
        this.media = {} as MediaInterface;
        if (this.wizardLarge) this.wizardLarge.reset();
      }
    );
  }
}
