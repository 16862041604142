import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ApiOutputInterface } from '../../core/interfaces/api.interface';
import { FileInterface } from '../../core/services/file.service';
import { ArtistInterface } from '../users/artist.service';
import { GenreInterface } from './genre.service';

export interface MediaInterface {
  uuid: string;
  name: string;
  description: string;
  is_showcased: boolean;
  is_visible: boolean;
  cover: FileInterface | string;
  genres: Array<GenreInterface> | Array<string>;

  link_youtube: string;
  link_spotify: string;
  link_deezer: string;
  link_soundcloud: string;
  link_tidal: string;
  link_itunes: string;
  link_bandcamp: string;

  owner: ArtistInterface;
  created_at: number;
}

export interface MediaHttpInterface extends ApiOutputInterface {
  media: MediaInterface;
}

interface MediumHttpInterface extends ApiOutputInterface {
  medium: Array<MediaInterface>;
  pages: number;
}

export interface DownloadTaskInterface {
  uuid: string;
  status: number;
  medium?: MediaInterface;
  owner?: ArtistInterface;
  logs?: string;
  source?: string;
  created_at: string;
}

export interface DownloadTaskInterfaceHttpInterface extends ApiOutputInterface {
  task?: DownloadTaskInterface;
  status: number;
}

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor(private http: HttpClient) {}

  public list(
    page: number = 1,
    sort: string = 'DDESC',
    category?: string,
    is_modo: boolean = false,
    artist?: string
  ): Promise<MediumHttpInterface> {
    return new Promise((resolve, reject) => {
      const url = is_modo
        ? environment.api + '/medium'
        : environment.api + '/medium/my';
      const params: any = {
        page: page.toString(),
        sort: sort,
      };
      if (category) params.genre = category;
      if (!is_modo && artist) params.artist = artist;

      this.http
        .get<MediumHttpInterface>(url, {
          params: params,
        })
        .subscribe(
          (output: MediumHttpInterface) => {
            return resolve(output);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  public create(media: MediaInterface): Promise<MediaInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .post<MediaHttpInterface>(environment.api + '/medium/new', media)
        .subscribe(
          (output: MediaHttpInterface) => {
            return resolve(output.media);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
  public edit(uuid: string, media: MediaInterface): Promise<MediaInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .post<MediaHttpInterface>(
          environment.api + '/medium/' + uuid + '/edit',
          media
        )
        .subscribe(
          (output: MediaHttpInterface) => {
            return resolve(output.media);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
  public showcase(uuid: string, showcased: boolean): Promise<MediaInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .post<MediaHttpInterface>(
          environment.api + '/medium/' + uuid + '/showcase',
          {
            showcased: showcased,
          }
        )
        .subscribe(
          (output: MediaHttpInterface) => {
            return resolve(output.media);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
  public visibility(
    uuid: string,
    is_visible: boolean
  ): Promise<MediaInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .post<MediaHttpInterface>(
          environment.api + '/medium/' + uuid + '/visibility',
          {
            visibility: is_visible,
          }
        )
        .subscribe(
          (output: MediaHttpInterface) => {
            return resolve(output.media);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
  public fetch(uuid: string): Promise<MediaInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .get<MediaHttpInterface>(environment.api + '/medium/' + uuid)
        .subscribe(
          (output: MediaHttpInterface) => {
            return resolve(output.media);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
  public delete(uuid: string): Promise<MediaInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .delete<MediaHttpInterface>(environment.api + '/medium/' + uuid)
        .subscribe(
          (output: MediaHttpInterface) => {
            return resolve(output.media);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
  public download(
    mediaUuid: string,
    source: string
  ): Promise<DownloadTaskInterface | undefined> {
    return new Promise((resolve, reject) => {
      this.http
        .post<DownloadTaskInterfaceHttpInterface>(
          environment.api + '/medium/' + mediaUuid + '/download/' + source,
          {}
        )
        .subscribe(
          (output: DownloadTaskInterfaceHttpInterface) => {
            return resolve(output.task);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
  public download_status(mediaUuid: string): Promise<number> {
    return new Promise((resolve, reject) => {
      this.http
        .get<DownloadTaskInterfaceHttpInterface>(
          environment.api + '/medium/' + mediaUuid + '/download'
        )
        .subscribe(
          (output: DownloadTaskInterfaceHttpInterface) => {
            return resolve(output.status);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
  public download_delete(mediaUuid: string): Promise<MediaInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .delete<MediaHttpInterface>(
          environment.api + '/medium/' + mediaUuid + '/download'
        )
        .subscribe(
          (output: MediaHttpInterface) => {
            return resolve(output.media);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
}
