import { Component, Input } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';

import { MediaInterface, MediaService } from '../../media.service';

@Component({
  selector: 'songs-download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.sass'],
})
export class DownloadButtonComponent {
  @Input() media!: MediaInterface;
  loading = ClrLoadingState.DEFAULT;
  status = 0;
  text = '<i class="fa fa-cloud-download"></i>';
  opened = false;

  constructor(private service: MediaService) {}

  ngOnInit(): void {
    this.update();
  }

  isDisabled(item?: string): boolean {
    return this.loading == ClrLoadingState.LOADING || this.status == 2;
  }
  wasDownloaded(): boolean {
    return this.status < 0 || this.status == 1 || this.status == 3;
  }
  showYoutube(): boolean {
    return this.media.link_youtube !== null && this.media.link_youtube !== '';
  }
  showSoundcloud(): boolean {
    return (
      this.media.link_soundcloud !== null && this.media.link_soundcloud !== ''
    );
  }
  getClass(): string {
    if (this.status == 0 || this.status == 1 || this.status == 2) {
      return 'btn btn-primary';
    } else if (this.status == 3) {
      return 'btn btn-success';
    } else if (this.status < 0) {
      return 'btn btn-danger';
    } else {
      return 'btn btn-secondary';
    }
  }
  getText(): string {
    if (this.loading) {
      return '<i class="fa fa-spin fa-spinner"></i>';
    } else if (this.status == 0) {
      return '<i class="fa fa-cloud-download"></i>';
    } else if (this.status == 1 || this.status == 2) {
      return '<i class="fa fa-clock-o"></i>';
    } else if (this.status == 3) {
      return '<i class="fa fa-check"></i>';
    } else if (this.status < 0) {
      return '<i class="fa fa-times"></i>';
    }
    return '<i class="fa fa-question"></i>';
  }

  download(source: string): void {
    this.loading = ClrLoadingState.LOADING;
    this.service.download(this.media.uuid, source).then(
      () => {
        this.update();
      },
      () => {
        this.loading = ClrLoadingState.DEFAULT;
      }
    );
  }

  delete(): void {
    this.loading = ClrLoadingState.LOADING;
    this.service.download_delete(this.media.uuid).then(
      () => {
        this.update();
      },
      () => {
        this.loading = ClrLoadingState.DEFAULT;
      }
    );
  }

  update(): void {
    this.loading = ClrLoadingState.LOADING;
    this.service.download_status(this.media.uuid).then(
      (status) => {
        this.status = status;
        this.loading = ClrLoadingState.DEFAULT;
      },
      () => {
        this.loading = ClrLoadingState.DEFAULT;
      }
    );
  }
}
