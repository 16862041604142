import { Component, Input, OnInit } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';

import { AuthService } from '../../../core/services/auth.service';
import { MediaInterface, MediaService } from '../media.service';
import { PlaylistInterface, PlaylistService } from '../playlist.service';

@Component({
  selector: 'app-songslist-visibility-button',
  template: `
    <button
      [clrLoading]="loading"
      (click)="setVisibility(false)"
      *ngIf="getVisibility()"
      class="btn btn-success btn-icon"
    >
      <i class="fa fa-eye"></i>
    </button>
    <button
      [clrLoading]="loading"
      (click)="setVisibility(true)"
      *ngIf="!getVisibility()"
      class="btn btn-danger btn-icon"
    >
      <i class="fa fa-eye-slash"></i>
    </button>
  `,
  styles: [],
})
export class SongsVisibilityButtonComponent implements OnInit {
  @Input() media: MediaInterface = {} as MediaInterface;
  @Input() playlist: PlaylistInterface = {} as PlaylistInterface;
  loading = ClrLoadingState.DEFAULT;

  constructor(
    private service: MediaService,
    private playlists_service: PlaylistService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {}

  getVisibility(): boolean {
    return this.media.uuid ? this.media.is_visible : this.playlist.is_visible;
  }

  setVisibility(is_visible: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      const local_service_func = this.media.uuid
        ? this.service.visibility
        : this.playlists_service.visibility;
      const local_uuid = this.media.uuid ? this.media.uuid : this.playlist.uuid;
      this.loading = ClrLoadingState.LOADING;

      let promise: Promise<any> | undefined = undefined;
      if (this.media.uuid) {
        promise = this.service.visibility(this.media.uuid, is_visible);
      } else if (this.playlist.uuid) {
        promise = this.playlists_service.visibility(
          this.playlist.uuid,
          is_visible
        );
      }

      if (promise) {
        promise.then(
          (artist) => {
            if (this.media.uuid) {
              this.media.is_visible = is_visible;
            } else if (this.playlist.uuid) {
              this.playlist.is_visible = is_visible;
            }
            this.loading = ClrLoadingState.DEFAULT;
          },
          (err) => {
            this.loading = ClrLoadingState.DEFAULT;
          }
        );
      }
    });
  }
}
