import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ApiOutputInterface } from '../../core/interfaces/api.interface';
import { EditableInterface } from '../../core/interfaces/editable.interface';
import { LoadersInterface } from '../../core/interfaces/loading.interface';

export interface GenreInterface extends EditableInterface, LoadersInterface {
  uuid: string;
  email: string;
  name: string;
}

interface GenreHttpInterface extends ApiOutputInterface {
  genre: GenreInterface;
}

interface GenresHttpInterface extends ApiOutputInterface {
  genres: Array<GenreInterface>;
}

@Injectable({
  providedIn: 'root',
})
export class GenreService {
  constructor(private http: HttpClient) {}

  public list(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<GenresHttpInterface>(environment.api + '/genres').subscribe(
        (output: GenresHttpInterface) => {
          return resolve(output.genres);
        },
        (err) => {
          return reject(err);
        }
      );
    });
  }
  public edit(uuid: string, name: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<GenreHttpInterface>(
          environment.api + '/genres/' + uuid + '/edit',
          {
            name: name,
          }
        )
        .subscribe(
          (output: GenreHttpInterface) => {
            return resolve(output.genre);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
  public create(name: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<GenreHttpInterface>(environment.api + '/genres/new', {
          name: name,
        })
        .subscribe(
          (output: GenreHttpInterface) => {
            return resolve(output.genre);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
  public delete(uuid: string) {
    return new Promise((resolve, reject) => {
      this.http
        .delete<GenreHttpInterface>(environment.api + '/genres/' + uuid)
        .subscribe(
          (output: GenreHttpInterface) => {
            return resolve(output.genre);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
}
