<div
  class="alert alert-{{ alert.type }}"
  role="alert"
  *ngFor="let alert of get(); let index = index"
  [class.alert-app-level]="pool == 'app'"
>
  <div class="alert-items">
    <div class="alert-item static">
      <div
        class="alert-icon-wrapper"
        [innerHtml]="generateIcon(alert.type) | safeHtml"
      ></div>
      <span class="alert-text">{{ alert.text }}</span>
    </div>
  </div>
  <button
    type="button"
    class="close"
    aria-label="Fermer"
    (click)="alert_service.remove(index, pool)"
  >
    <cds-icon aria-hidden="true" shape="close"></cds-icon>
  </button>
</div>
