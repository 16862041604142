<div class="main-container" *ngIf="auth.isAuthenticated()">
  <ui-alerts pool="app"></ui-alerts>
  <ui-header></ui-header>

  <div class="content-container">
    <ui-alerts></ui-alerts>
    <router-outlet></router-outlet>
  </div>
</div>

<div class="main-container login-container" *ngIf="!auth.isAuthenticated()">
  <ui-alerts pool="app"></ui-alerts>

  <div class="content-container">
    <ui-login></ui-login>
  </div>
</div>
