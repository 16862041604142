<table class="table table-noborder table-compact table-noborder">
  <thead>
    <tr>
      <th>Pochette</th>
      <th>Nom</th>
      <th>Genres</th>
      <th>Date d'ajout</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="loading">
      <td colspan="5">Chargement en cours...</td>
    </tr>

    <tr *ngIf="!loading && medium.length < 1">
      <td colspan="5">
        Aucun son trouvé.
        <a *ngIf="!isModo" routerLink="/tracks" routerLinkActive="active"
          >Pourquoi ne pas en ajouter un ?</a
        >
      </td>
    </tr>

    <ng-container *ngIf="!loading">
      <tr *ngFor="let media of medium; let i = index">
        <td>
          <img
            *ngIf="media.cover"
            class="cover"
            width="30"
            [src]="fileService.link(getCover(media))"
            alt="Pochette de {{ media.name }}"
          />
          <img
            *ngIf="!media.cover"
            class="cover"
            width="30"
            src="/assets/images/cover_default.png"
            alt="Pochette de {{ media.name }}"
          />
        </td>
        <td>
          <strong>{{ media.name }} </strong>
          <ng-container *ngIf="isModo">
            par
            <a
              href="{{ seo.getWebsiteEndpoint() }}/artistes/{{
                media.owner.pseudo
              }}"
              target="_blank"
              >{{ media.owner.pseudo }}</a
            >
          </ng-container>
        </td>
        <td>
          <div *ngFor="let genre of getGenres(media)" class="badge">
            {{ genre.name }}
          </div>
        </td>
        <td>{{ media.created_at * 1000 | date : "shortDate" }}</td>
        <td class="actions">
          <ng-template
            [ngIf]="!isSelected(media.uuid)"
            [ngIfElse]="notSelected"
          >
            <button
              (click)="add(i)"
              title="Ajouter"
              type="button"
              class="btn btn-primary btn-icon"
            >
              <i class="fa fa-check"></i>
            </button>
          </ng-template>
          <ng-template #notSelected>
            <button
              (click)="remove(i)"
              title="Retirer"
              type="button"
              class="btn btn-danger btn-icon"
            >
              <i class="fa fa-times"></i>
            </button>
          </ng-template>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

<ui-pager
  #pager
  [pageMax]="pageMax"
  (next)="on_next()"
  (previous)="on_previous()"
></ui-pager>
