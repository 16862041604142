import { Injectable } from '@angular/core';

interface AlertsInterface {
  type: string;
  text: string;
}

interface AlertsPoolsInterface {
  [key: string]: Array<AlertsInterface>;
}

interface AlertsIconsInterface {
  [key: string]: string;
}

@Injectable()
export class AlertService {
  private alerts_pool: AlertsPoolsInterface = {
    app: [],
    standard: [],
  };
  private alerts_icons: AlertsIconsInterface = {
    danger: 'exclamation-circle',
    warning: 'exclamation-triangle',
    success: 'check-circle',
    info: 'info-circle',
  };

  constructor() {}

  public getAlerts(pool = 'standard'): Array<AlertsInterface> {
    return this.alerts_pool[pool];
  }

  public add(alert: AlertsInterface, pool = 'standard'): void {
    this.alerts_pool[pool].push(alert);
  }

  public clear(pool = 'standard'): void {
    if (this.alerts_pool[pool]) this.alerts_pool[pool] = [];
  }

  public remove(i: number, pool = 'standard'): void {
    this.alerts_pool[pool].splice(i, 1);
  }

  public getIcon(type: string): string {
    return this.alerts_icons[type];
  }

  public success(txt: string, pool = 'standard'): void {
    this.add({ type: 'success', text: txt });
  }
  public warning(txt: string, pool = 'standard'): void {
    this.add({ type: 'warning', text: txt });
  }
  public danger(txt: string, pool = 'standard'): void {
    this.add({ type: 'danger', text: txt });
  }
  public error(txt: string, pool = 'standard'): void {
    this.add({ type: 'danger', text: txt });
  }
  public info(txt: string, pool = 'standard'): void {
    this.add({ type: 'info', text: txt });
  }
}
