import { AuthGuard } from 'src/app/core/guards/auth.guard';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthModoGuard } from '../../core/guards/auth-modo.guard';
import { GenresListComponent } from './genres/genres.component';
import { PlaylistsListComponent } from './playlists/playlists.component';
import { SongsListComponent } from './songslist/songslist.component';

const routes: Routes = [
  {
    path: '',
    component: SongsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'moderation',
    component: SongsListComponent,
    canActivate: [AuthModoGuard],
  },
  {
    path: 'playlists',
    component: PlaylistsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'playlists/moderation',
    component: PlaylistsListComponent,
    canActivate: [AuthModoGuard],
  },
  {
    path: 'genres',
    component: GenresListComponent,
    canActivate: [AuthModoGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SongsRoutingModule {}
