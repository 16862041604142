import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ui-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.sass'],
})
export class BreadcrumbComponent {
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() parent: string = '';
  @Input() parentUrl: string = '';

  constructor(private router: Router) {}

  gotoParent(): void {
    if (this.parentUrl) this.router.navigate([`${this.parentUrl}`]);
  }
}
