<clr-vertical-nav>
  <label class="nav-header"> Ordre de tri </label>
  <a
    clrVerticalNavLink
    (click)="setOrder('DDESC')"
    [class.active]="getOrder() == 'DDESC'"
    >Date d'ajout <em>(plus récent d'abord)</em></a
  >
  <a
    clrVerticalNavLink
    (click)="setOrder('DASC')"
    [class.active]="getOrder() == 'DASC'"
    >Date d'ajout <em>(plus ancien d'abord)</em></a
  >
  <a
    clrVerticalNavLink
    (click)="setOrder('NASC')"
    [class.active]="getOrder() == 'NASC'"
    >Nom <em>(ordre alphabétique)</em></a
  >
  <a
    clrVerticalNavLink
    (click)="setOrder('NDESC')"
    [class.active]="getOrder() == 'NDESC'"
    >Nom <em>(ordre anti-alphabétique)</em></a
  >
  <div class="nav-divider"></div>
  <label class="nav-header"> Catégorie </label>
  <a
    clrVerticalNavLink
    (click)="setCategory(genre.uuid)"
    [class.active]="getCategory() == genre.uuid"
    *ngFor="let genre of genres"
    >{{ genre.name }}</a
  >
</clr-vertical-nav>

<div class="content-area">
  <div class="head">
    <ui-breadcrumb
      title="Mes playlists"
      parent="Sons"
      parentUrl="songs/list"
    ></ui-breadcrumb>

    <div class="btns">
      <button (click)="lgOpen = true" class="btn btn-primary">
        <i class="fa fa-plus"></i> Ajouter
      </button>
    </div>
  </div>

  <table class="table">
    <thead>
      <tr>
        <th>Pochette</th>
        <th>Nom</th>
        <th>Type</th>
        <th>Genres</th>
        <th>Visible</th>
        <th>Date d'ajout</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loading">
        <td colspan="7">Chargement en cours...</td>
      </tr>

      <tr *ngIf="!loading && playlists.length < 1">
        <td colspan="7">
          Aucune playlist trouvée.
          <a *ngIf="!moderation_mode" (click)="lgOpen = true"
            >Pourquoi ne pas en crééer une ?</a
          >
        </td>
      </tr>

      <ng-container *ngIf="!loading">
        <tr *ngFor="let playlist of playlists; let i = index">
          <td>
            <img
              *ngIf="playlist.cover"
              class="cover"
              width="75"
              [src]="fileService.link(getCover(playlist))"
              alt="Pochette de {{ playlist.name }}"
            />
            <img
              *ngIf="!playlist.cover"
              class="cover"
              width="75"
              src="/assets/img/cover_default.png"
              alt="Pochette de {{ playlist.name }}"
            />
          </td>
          <td>
            <strong>{{ playlist.name }}</strong>
            <br />par
            <a
              href="{{ seo.getWebsiteEndpoint() }}/artistes/{{
                playlist.owner.pseudo
              }}"
              target="_blank"
              >{{ playlist.owner.pseudo }}</a
            >
            <i class="fa fa-external-link"></i>
          </td>
          <td>
            <div *ngIf="playlist.type == 0" class="badge badge-purple">
              Playlist
            </div>
            <div *ngIf="playlist.type == 1" class="badge badge-purple">
              Album
            </div>
            <div *ngIf="playlist.type == 2" class="badge badge-purple">
              Mixtape
            </div>
            <div *ngIf="playlist.type == 3" class="badge badge-purple">EP</div>
          </td>
          <td>
            <div
              *ngFor="let genre of playlist.genres"
              class="badge badge-purple"
            >
              {{ genre.name }}
            </div>
          </td>
          <td>
            <app-songslist-visibility-button
              [playlist]="playlist"
            ></app-songslist-visibility-button>
          </td>
          <td>
            <span class="badge badge-secondary">{{
              playlist.created_at * 1000 | date : "short"
            }}</span>
          </td>
          <td>
            <div class="btn-group" role="group">
              <button class="btn btn-primary btn-icon" (click)="edit(playlist)">
                <i class="fa fa-pencil"></i>
              </button>
              <button
                class="btn btn-danger btn-icon"
                (click)="confirmDeletion(playlist.uuid)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <ui-pager
    #pager
    [pageMax]="pageMax"
    (next)="on_next()"
    (previous)="on_previous()"
  ></ui-pager>
</div>

<clr-wizard
  #wizardlg
  [(clrWizardOpen)]="lgOpen"
  clrWizardSize="lg"
  (clrWizardOnFinish)="doFinish()"
  (clrWizardOnCancel)="doCancel()"
>
  <clr-wizard-title>Nouvelle playlist</clr-wizard-title>

  <clr-wizard-button [type]="'cancel'">Annuler</clr-wizard-button>
  <clr-wizard-button [type]="'previous'">Retour</clr-wizard-button>
  <clr-wizard-button [type]="'next'">Suivant</clr-wizard-button>
  <clr-wizard-button [type]="'finish'">Terminer</clr-wizard-button>

  <clr-wizard-page [clrWizardPageNextDisabled]="!wizardValidationFirstPage()">
    <ng-template clrPageTitle>Informations de base</ng-template>
    <form clrForm>
      <clr-input-container>
        <label for="InputName" class="clr-required-mark"
          >Nom de la playlist</label
        >
        <input
          clrInput
          placeholder="Ma super mixtape"
          id="InputName"
          required
          name="name"
          [(ngModel)]="playlist.name"
        />
        <clr-control-error>Ce champs est requis.</clr-control-error>
      </clr-input-container>

      <clr-textarea-container>
        <label for="InputDescription">Description de la playlist</label>
        <textarea
          rows="5"
          clrTextarea
          [(ngModel)]="playlist.description"
          name="description"
          id="InputDescription"
        ></textarea>
        <clr-control-helper
          >Rédigez quelques mots à propos de votre playlist</clr-control-helper
        >
      </clr-textarea-container>

      <clr-input-container>
        <label for="InputType" class="clr-required-mark"
          >Type de playlist</label
        >
        <select
          clrInput
          required
          id="InputType"
          required
          name="type"
          [(ngModel)]="playlist.type"
        >
          <option value="-1" disabled>Sélectionner un type de playlist</option>
          <option value="0">Playlist</option>
          <option value="1">Album</option>
          <option value="2">Mixtape</option>
          <option value="3">EP</option>
        </select>

        <clr-control-error>Ce champs est requis.</clr-control-error>
      </clr-input-container>

      <clr-checkbox-container>
        <label class="clr-required-mark">Genres musicaux</label>
        <clr-checkbox-wrapper *ngFor="let genre of genres">
          <input
            type="checkbox"
            clrCheckbox
            value="{{ genre.uuid }}"
            name="categories"
            (change)="toggleGenre(genre)"
            [checked]="isChecked(genre.uuid)"
          />
          <label>{{ genre.name }}</label>
        </clr-checkbox-wrapper>
        <clr-control-error
          >Veuillez sélectionner au moins un genre musical.</clr-control-error
        >
      </clr-checkbox-container>
    </form>
  </clr-wizard-page>

  <clr-wizard-page [clrWizardPageNextDisabled]="!wizardValidationSecondPage()">
    <ng-template clrPageTitle>Tubes à inclures</ng-template>
    <form clrForm>
      <app-playlistslist-songs
        [selected]="playlist.medium"
        [isModo]="moderation_mode"
        (added)="on_medium_add($event)"
        (removed)="on_medium_remove($event)"
      ></app-playlistslist-songs>
    </form>
  </clr-wizard-page>

  <clr-wizard-page>
    <ng-template clrPageTitle>Pochette</ng-template>

    <form clrForm>
      <clr-input-container>
        <label>Image</label>
        <input
          clrInput
          #cover
          type="file"
          (change)="uploadCover($event)"
          multiple="false"
          accept="image/jpeg,image/png"
          [disabled]="loading"
        />
      </clr-input-container>

      <img
        [src]="fileService.link(getCover(playlist))"
        alt="Cover de {{ playlist.name }}"
        class="cover"
        height="450"
        width="450"
        *ngIf="playlist.cover"
      />
    </form>
  </clr-wizard-page>
</clr-wizard>

<clr-modal [(clrModalOpen)]="dlOpen">
  <h3 class="modal-title">Confirmer la suppression</h3>
  <div class="modal-body">
    <p>
      Etes-vous sûr de vouloir supprimer cette playlist ?
      <strong>Vous ne pourrez pas la récupérer par la suite !</strong>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="dlOpen = false">
      Annuler
    </button>
    <button type="button" class="btn btn-primary" (click)="delete()">
      Confirmer
    </button>
  </div>
</clr-modal>
