export const environment = {
  production: true,
  api: 'https://api.trackconnection.paas.chupin.xyz',

  app_name: 'TrackConnection',
  seo_suffix: '- MyTrackConnection',

  loading_delay: 1000,

  login_back: 'https://www.trackconnection.paas.chupin.xyz',
  discord_invitation: 'https://discord.gg/B6uNT3N',

  website_endpoint: 'https://www.trackconnection.paas.chupin.xyz',
  backoffice_endpoint: 'https://my.trackconnection.paas.chupin.xyz',
  webradio_endpoint:
    'https://webradio.trackconnection.paas.chupin.xyz/public/trackconnection',
  webradio_api_endpoint: 'https://webradio.trackconnection.paas.chupin.xyz/api',
  status_endpoint: 'https://uptime.home.chupin.xyz/status/trackconnection',
  boombox_topgg_endpoint: 'https://top.gg/fr/bot/928344372242677831',
  boombox_invitation:
    'https://discord.com/oauth2/authorize?client_id=928344372242677831&scope=bot&permissions=274881121344',
  grafana_public_endpoint:
    'https://grafana.home.chupin.xyz/public-dashboards/2d5cb0d38d4546398e4df510c4cbbf84',
};
