import '@cds/core/icon/register.js';

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { ClarityModule } from '@clr/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { AuthAdminGuard } from './core/guards/auth-admin.guard';
import { AuthModoGuard } from './core/guards/auth-modo.guard';
import { AuthSelfGuard } from './core/guards/auth-self.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { HttpConfigInterceptor } from './core/helpers/httpconfig.interceptor';
import { SafeHtmlPipe } from './core/pipes/safe-html.pipe';
import { AlertService } from './core/services/alert.service';
import { AuthService } from './core/services/auth.service';
import { SongsModule } from './pages/songs/songs.module';
import { SharedModule } from './shared/shared.module';

export function getToken() {
  return localStorage.getItem('APP_TOKEN');
}

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    AlertsComponent,
    SafeHtmlPipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ClarityModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    SongsModule,
    FormsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
      },
    }),
  ],
  providers: [
    AuthGuard,
    AuthAdminGuard,
    AuthModoGuard,
    AuthSelfGuard,
    AuthService,
    AlertService,

    { provide: LOCALE_ID, useValue: 'fr-FR' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
