import { AuthService } from 'src/app/core/services/auth.service';
import { FileInterface, FileService } from 'src/app/core/services/file.service';
import { ArtistInterface } from 'src/app/pages/users/artist.service';
import { environment } from 'src/environments/environment';

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { GenreInterface, GenreService } from '../../genre.service';
import { MediaInterface, MediaService } from '../../media.service';
import { SEOService } from 'src/app/core/services/seo.service';

@Component({
  selector: 'app-playlistslist-songs',
  templateUrl: './playlistslist-songs.component.html',
  styleUrls: ['./playlistslist-songs.component.sass'],
})
export class PlaylistslistSongsComponent {
  genres: Array<GenreInterface> = [];
  medium: Array<MediaInterface> = [];

  @Input() owner!: ArtistInterface;
  @Input() isModo = false;
  @Input() selected: Array<MediaInterface> = [];
  @Output() added = new EventEmitter<MediaInterface>();
  @Output() removed = new EventEmitter<MediaInterface>();

  sort = 'DDESC';
  genre = 'all';
  page = 1;
  pageMax = 1;
  loading = false;

  constructor(
    private service: MediaService,
    private genreService: GenreService,
    public fileService: FileService,
    private auth: AuthService,
    public seo: SEOService
  ) {}

  ngOnInit(): void {
    this.loading = true;

    Promise.all([this.genreService.list(), this.update()]).then(
      ([genres]) => {
        this.genres = genres;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  isSelected(uuid: string): boolean {
    if (!this.selected) return false;
    let i = 0;
    let found = false;
    while (!found && i < this.selected.length) {
      if (this.selected[i].uuid === uuid) {
        found = true;
      } else {
        i += 1;
      }
    }

    return found;
  }

  add(index: number) {
    // this.selected.push(this.medium[index]);
    this.added.emit(this.medium[index]);
  }

  remove(index: number) {
    this.removed.emit(this.medium[index]);
    // this.selected.splice(index, 1);
  }

  getImageLink(uuid: string): string {
    return environment.api + '/images/' + uuid;
  }

  getGenres(media: MediaInterface): Array<GenreInterface> {
    return media.genres as Array<GenreInterface>;
  }

  getCover(media: MediaInterface): string {
    return (media.cover as FileInterface).uuid;
  }

  on_next() {
    this.page += 1;
    this.update();
  }

  on_previous() {
    this.page -= 1;
    this.update();
  }

  update(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.service
        .list(
          this.page,
          this.sort,
          this.genre,
          this.isModo,
          !this.isModo ? this.auth.getUuid() : undefined
        )
        .then(
          (output) => {
            this.medium = output.medium;
            this.pageMax = output.pages;
            return resolve();
          },
          (err: Error) => {
            return reject(err);
          }
        );
    });
  }
}
