import { AlertService } from 'src/app/core/services/alert.service';

import { Component, OnInit } from '@angular/core';

import { SEOService } from '../../../core/services/seo.service';
import { GenreInterface, GenreService } from '../genre.service';

@Component({
  selector: 'tracks-genres',
  templateUrl: './genres.component.html',
  styleUrls: ['./genres.component.sass'],
})
export class GenresListComponent implements OnInit {
  // Common part
  loading = false;
  genres: Array<GenreInterface> = [];

  // Listing part
  sort = 'DDESC';
  page = 1;
  pageMax = 1;

  // Edition part
  lgOpen: boolean = false;
  genre: GenreInterface = {} as GenreInterface;

  // Deletion part
  dlOpen: boolean = false;
  toDelete: string | null = null;

  constructor(
    private service: GenreService,
    private seo: SEOService,
    private notifier: AlertService
  ) {}

  ngOnInit(): void {
    this.update();
    this.seo.updateTitle('Gestion des genres musicaux');
    this.seo.injectCanonical();
  }

  on_next(): void {
    this.page += 1;
    this.update();
  }
  on_previous(): void {
    this.page -= 1;
    this.update();
  }

  confirmDeletion(uuid: string): void {
    this.toDelete = uuid;
    this.dlOpen = true;
  }

  delete(): void {
    if (this.toDelete)
      this.service.delete(this.toDelete).then(() => {
        this.toDelete = null;
        this.update();
        this.dlOpen = false;
        this.notifier.success('Genre supprimé avec succès');
      });
  }

  edit(genre: GenreInterface): void {
    this.genre = JSON.parse(JSON.stringify(genre));
    this.lgOpen = true;
  }

  update() {
    this.loading = true;
    this.service.list().then((categories: Array<GenreInterface>) => {
      this.genres = categories;
      this.loading = false;
    });
  }

  doCancel(): void {
    this.genre = {} as GenreInterface;
    this.lgOpen = false;
  }

  doFinish(): void {
    this.loading = true;

    let promise: any;
    if (this.genre.uuid) {
      promise = this.service.edit(this.genre.uuid, this.genre.name);
    } else {
      promise = this.service.create(this.genre.name);
    }

    promise.then(
      () => {
        this.loading = false;
        this.lgOpen = false;

        if (this.genre.uuid) {
          this.notifier.success('Genre musical édité avec succès !');
        } else {
          this.notifier.success('Genre musical ajouté avec succès !');
        }

        this.genre = {} as GenreInterface;
        this.update();
      },
      (err: Error) => {
        this.loading = false;
        this.lgOpen = false;
        this.genre = {} as GenreInterface;
      }
    );
  }
}
