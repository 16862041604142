import { Component } from '@angular/core';
import {
  administratorIcon,
  angleIcon,
  calendarIcon,
  cdDvdIcon,
  checkCircleIcon,
  checkIcon,
  ClarityIcons,
  cogIcon,
  exclamationCircleIcon,
  exclamationTriangleIcon,
  eyeHideIcon,
  eyeIcon,
  floppyIcon,
  folderIcon,
  homeIcon,
  infoCircleIcon,
  loginIcon,
  logoutIcon,
  noteIcon,
  pencilIcon,
  timesIcon,
  toolsIcon,
  trashIcon,
  uploadIcon,
  userIcon,
  usersIcon,
  worldIcon,
  wrenchIcon,
} from '@cds/core/icon';

import { AlertService } from './core/services/alert.service';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent {
  title = 'backoffice';

  constructor(public auth: AuthService, public alert_service: AlertService) {}

  ngOnInit() {
    ClarityIcons.addIcons(
      homeIcon,
      cdDvdIcon,
      folderIcon,
      noteIcon,
      calendarIcon,
      administratorIcon,
      usersIcon,
      userIcon,
      trashIcon,
      pencilIcon,
      wrenchIcon,
      uploadIcon,
      worldIcon,
      toolsIcon,
      loginIcon,
      logoutIcon,
      exclamationCircleIcon,
      exclamationTriangleIcon,
      checkCircleIcon,
      infoCircleIcon,
      angleIcon,
      cogIcon,
      floppyIcon,
      checkIcon,
      timesIcon,
      eyeIcon,
      eyeHideIcon
    );
  }
}
