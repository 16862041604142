import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ApiOutputInterface } from '../../core/interfaces/api.interface';
import { FileInterface } from '../../core/services/file.service';
import { ArtistInterface } from '../users/artist.service';
import { GenreInterface } from './genre.service';
import { MediaInterface } from './media.service';

export interface PlaylistInterface {
  uuid: string;
  type: number;
  name: string;
  description: string;

  cover: FileInterface | string;
  genres: Array<GenreInterface>;
  owner: ArtistInterface;
  medium: Array<MediaInterface>;

  link_youtube: string;
  link_spotify: string;
  link_deezer: string;
  link_soundcloud: string;
  link_tidal: string;
  link_itunes: string;
  link_bandcamp: string;

  is_showcased: boolean;
  is_visible: boolean;
  created_at: number;
}

export interface PlaylistHttpInterface extends ApiOutputInterface {
  playlist: PlaylistInterface;
}

interface PlaylistsHttpInterface extends ApiOutputInterface {
  playlists: Array<PlaylistInterface>;
  pages: number;
}

@Injectable({
  providedIn: 'root',
})
export class PlaylistService {
  constructor(private http: HttpClient) {}

  public list(
    page: number = 1,
    sort: string = 'DDESC',
    category: string = '-1',
    is_modo: boolean = false
  ): Promise<PlaylistsHttpInterface> {
    return new Promise((resolve, reject) => {
      const url = is_modo
        ? environment.api + '/playlists'
        : environment.api + '/playlists/my';
      this.http
        .get<PlaylistsHttpInterface>(url, {
          params: { page: page.toString(), sort: sort, genre: category },
        })
        .subscribe(
          (output: PlaylistsHttpInterface) => {
            return resolve(output);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  public create(playlist: PlaylistInterface): Promise<PlaylistInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .post<PlaylistHttpInterface>(
          environment.api + '/playlists/new',
          playlist
        )
        .subscribe(
          (output: PlaylistHttpInterface) => {
            return resolve(output.playlist);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
  public edit(uuid: string, media: MediaInterface): Promise<PlaylistInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .post<PlaylistHttpInterface>(
          environment.api + '/playlists/' + uuid + '/edit',
          media
        )
        .subscribe(
          (output: PlaylistHttpInterface) => {
            return resolve(output.playlist);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
  // public showcase(uuid: string, showcased: boolean): Promise<MediaInterface> {
  //   return new Promise((resolve, reject) => {
  //     this.http
  //       .post(environment.api + '/medium/' + uuid + '/showcase', {
  //         showcased: showcased,
  //       })
  //       .subscribe(
  //         (output: MediaHttpInterface) => {
  //           return resolve(output.media);
  //         },
  //         (err) => {
  //           return reject(err);
  //         }
  //       );
  //   });
  // }
  public visibility(
    uuid: string,
    is_visible: boolean
  ): Promise<PlaylistInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .post<PlaylistHttpInterface>(
          environment.api + '/playlists/' + uuid + '/visibility',
          {
            visibility: is_visible,
          }
        )
        .subscribe(
          (output: PlaylistHttpInterface) => {
            return resolve(output.playlist);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
  public fetch(uuid: string): Promise<PlaylistInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .get<PlaylistHttpInterface>(environment.api + '/playlists/' + uuid)
        .subscribe(
          (output: PlaylistHttpInterface) => {
            return resolve(output.playlist);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
  public delete(uuid: string): Promise<PlaylistInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .delete<PlaylistHttpInterface>(environment.api + '/playlists/' + uuid)
        .subscribe(
          (output: PlaylistHttpInterface) => {
            return resolve(output.playlist);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
}
