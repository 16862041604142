<header class="header-3">
  <div class="branding">
    <a routerLink="/" class="nav-link">
      <img
        src="/assets/img/logo.png"
        alt="Logo de {{ env_app_name }}"
        class="brand-image"
        width="200"
      />
    </a>
  </div>
  <div class="header-nav">
    <a
      routerLink="/dashboards"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="nav-link"
    >
      <cds-icon shape="home"></cds-icon>
    </a>
    <a
      routerLink="/tracks"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="nav-link"
    >
      <cds-icon shape="cd-dvd"></cds-icon>
    </a>
    <a
      routerLink="/tracks/playlists"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="nav-link"
    >
      <cds-icon shape="folder"></cds-icon>
    </a>
    <a
      routerLink="/directory"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="nav-link"
    >
      <cds-icon shape="users"></cds-icon>
    </a>
    <a
      routerLink="/notes"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="nav-link"
      *ngIf="getAttribute('power') >= 75"
    >
      <cds-icon shape="note"></cds-icon>
    </a>
    <a
      routerLink="/agenda"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="nav-link"
      *ngIf="getAttribute('power') >= 75"
    >
      <cds-icon shape="calendar"></cds-icon>
    </a>
  </div>
  <div class="header-actions">
    <clr-dropdown *ngIf="getAttribute('power') >= 50">
      <button
        class="nav-icon"
        clrDropdownTrigger
        aria-label="toggle moderation menu"
      >
        <cds-icon shape="tools"></cds-icon>
        <cds-icon shape="angle" direction="down"></cds-icon>
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <a
          routerLink="/tracks/moderation"
          routerLinkActive="active"
          clrDropdownItem
          >Gestion des titres</a
        >
        <a
          routerLink="/tracks/playlists/moderation"
          routerLinkActive="active"
          clrDropdownItem
          >Gestion des playlists</a
        >
      </clr-dropdown-menu>
    </clr-dropdown>

    <clr-dropdown *ngIf="getAttribute('power') >= 75">
      <button
        class="nav-icon"
        clrDropdownTrigger
        aria-label="toggle settings menu"
      >
        <cds-icon shape="cog"></cds-icon>
        <cds-icon shape="angle" direction="down"></cds-icon>
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <a
          routerLink="/directory/moderation"
          routerLinkActive="active"
          clrDropdownItem
          >Gestion des utilisateurs</a
        >
        <div class="dropdown-divider" role="separator"></div>
        <a routerLink="/tracks/genres" routerLinkActive="active" clrDropdownItem
          >Gestion des genres</a
        >
        <a
          routerLink="/directory/roles"
          routerLinkActive="active"
          clrDropdownItem
          >Gestion des rôles</a
        >
      </clr-dropdown-menu>
    </clr-dropdown>

    <clr-dropdown>
      <button
        class="nav-text"
        clrDropdownTrigger
        aria-label="open user profile"
      >
        <img
          #avatar
          width="28"
          class="rounded-circle"
          src="{{ getAvatar() }}"
          alt="Avatar de {{ auth.getProfile().pseudo }}"
        />

        {{ auth.getProfile().pseudo }}
        <cds-icon shape="angle" direction="down"></cds-icon>
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <a
          routerLink="/directory/self"
          routerLinkActive="active"
          clrDropdownItem
          >Mon profil</a
        >
        <a href="..." clrDropdownItem *ngIf="getAttribute('power') >= 75"
          >Préférences</a
        >
        <div class="dropdown-divider" role="separator"></div>
        <a (click)="disconnect($event)" clrDropdownItem>Se déconnecter</a>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>
</header>

<!--<nav class="main-header navbar navbar-expand-md navbar-dark">
  <div class="container">
    <a (click)="goto('dashboards/home')" class="navbar-brand">
      <img
        src="/assets/img/logo.png"
        alt="Logo de trackconnection.net"
        class="brand-image"
      />
    </a>

    <button
      class="navbar-toggler"
      type="button"
      aria-label="Menu principal"
      [class.opened]="opened"
      (click)="toggle()"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse" [class.collapse]="!opened" (click)="toggle()">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a
            role="button"
            routerLink="songs/list"
            routerLinkActive="active"
            class="nav-link"
          >
            <i class="fa fa-music"></i>
            <span class="d-lg-none d-xl-inline">Mes tubes</span>
          </a>
        </li>

        <li class="nav-item">
          <a
            role="button"
            routerLink="songs/playlists/list"
            routerLinkActive="active"
            class="nav-link"
          >
            <i class="fa fa-folder"></i>
            <span class="d-lg-none d-xl-inline">Mes playlists</span>
          </a>
        </li>

        <li ngbDropdown class="nav-item dropdown">
          <a
            id="modoDropdown"
            role="button"
            ngbDropdownToggle
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="nav-link dropdown-toggle"
          >
            <i class="fa fa-gavel"></i>
            <span class="d-lg-none d-xl-inline">Modération</span>
          </a>

          <div
            ngbDropdownMenu
            aria-labelledby="modoDropdown"
            class="dropdown-menu border-0 shadow"
          >
            <a
              role="button"
              routerLink="songs/list/moderation"
              routerLinkActive="active"
              class="dropdown-item"
            >
              <i class="fa fa-music"></i> Liste des tubes
            </a>
            <a
              role="button"
              routerLink="songs/playlists/list/moderation"
              routerLinkActive="active"
              class="dropdown-item"
            >
              <i class="fa fa-folder"></i> Liste des playlists
            </a>
          </div>
        </li>

        <li ngbDropdown class="nav-item dropdown">
          <a
            id="adminDropdown"
            role="button"
            ngbDropdownToggle
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="nav-link dropdown-toggle"
          >
            <i class="fa fa-cogs"></i>
            <span class="d-lg-none d-xl-inline">Administration</span>
          </a>

          <div
            ngbDropdownMenu
            aria-labelledby="adminDropdown"
            class="dropdown-menu border-0 shadow"
          >
            <a
              role="button"
              routerLink="users/list"
              routerLinkActive="active"
              class="dropdown-item"
            >
              <i class="fa fa-users"></i> Liste des artistes
            </a>
            <a
              role="button"
              routerLink="users/roles/list"
              routerLinkActive="active"
              class="dropdown-item"
            >
              <i class="fa fa-tag"></i> Liste des rôles
            </a>
            <a
              role="button"
              routerLink="songs/genres/list"
              routerLinkActive="active"
              class="dropdown-item"
            >
              <i class="fa fa-tag"></i> Liste des genres
            </a>
          </div>
        </li>
      </ul>
    </div>

    <ul class="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
      <li ngbDropdown class="nav-item dropdown">
        <a
          id="dashboardsDropdown"
          role="button"
          ngbDropdownToggle
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          class="nav-link dropdown-toggle"
        >
          {{ getAttribute("pseudo") }}
          <img
            #avatar
            width="28"
            class="rounded-circle"
            src="{{ getAvatar() }}"
            alt="Avatar de {{ getAttribute('pseudo') }}"
          />
        </a>

        <div
          ngbDropdownMenu
          aria-labelledby="dashboardsDropdown"
          class="dropdown-menu border-0 shadow"
        >
          <a
            role="button"
            target="_blank"
            href="https://www.trackconnection.net/artistes/{{
              getAttribute('pseudo')
            }}"
            class="dropdown-item"
          >
            <i class="fa fa-user"></i> Voir mon profil
            <i class="fa fa-external-link"></i>
          </a>
          <a
            role="button"
            routerLink="users/{{ getAttribute('uuid') }}/edit"
            routerLinkActive="active"
            class="dropdown-item"
          >
            <i class="fa fa-pencil"></i> Editer mon profil
          </a>
          <a
            role="button"
            routerLink="users/settings"
            routerLinkActive="active"
            class="dropdown-item"
          >
            <i class="fa fa-wrench"></i> Paramètres
          </a>

          <div class="dropdown-divider"></div>

          <a role="button" (click)="disconnect($event)" class="dropdown-item">
            <i class="fa fa-sign-out"></i> Se déconnecter
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav>
/.navbar-->
