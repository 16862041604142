import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ApiOutputInterface } from '../interfaces/api.interface';

export interface FileInterface {
  uuid: string;
  filename: string;
  mimetype: string;
  created_at: number;
}

interface FileHttpInterface extends ApiOutputInterface {
  file: FileInterface;
}

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private http: HttpClient) {}

  public link(uuid: string): string {
    return environment.api + '/files/' + uuid;
  }

  public upload(file: any, type?: string): Promise<FileInterface> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file, file.name);

      let url = environment.api + '/files/upload';
      if (type) {
        url += `?type=${type}`;
      }

      this.http.post<FileHttpInterface>(url, formData).subscribe(
        (output: FileHttpInterface) => {
          return resolve(output.file);
        },
        (err) => {
          return reject(err);
        }
      );
    });
  }
}
