import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'ui-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.sass'],
})
export class PagerComponent {
  @Input() public pageMax = 1;
  @Output() next: EventEmitter<number> = new EventEmitter();
  @Output() previous: EventEmitter<number> = new EventEmitter();

  public pageCurrent = 1;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['pageMax'] && changes['pageMax'].currentValue <= 0)
      this.pageMax = 1;
  }

  public get_current() {
    return this.pageCurrent;
  }

  public page_next() {
    if (this.pageCurrent < this.pageMax) {
      this.pageCurrent++;
      return this.next.emit(this.pageCurrent);
    }
  }

  public page_previous() {
    if (this.pageCurrent > 1) {
      this.pageCurrent--;
      return this.previous.emit(this.pageCurrent);
    }
  }
}
