<div class="pager">
  <button
    type="button"
    class="btn btn-primary btn-icon btn-sm"
    (click)="page_previous()"
    [class.disabled]="pageCurrent <= 1"
  >
    <i class="fa fa-arrow-left"></i>
  </button>

  <span class="current-pager">
    <strong>Page {{ pageCurrent }}</strong> - {{ pageMax }}
  </span>

  <button
    type="button"
    class="btn btn-primary btn-icon btn-sm"
    (click)="page_next()"
    [class.disabled]="pageCurrent >= pageMax"
  >
    <i class="fa fa-arrow-right"></i>
  </button>
</div>
