import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

// import { NotifierService } from 'angular-notifier';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AlertService } from '../services/alert.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private notifier: AlertService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: string | null = localStorage.getItem('APP_TOKEN');

    if (token) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token),
      });
    }

    if (
      !request.headers.has('Content-Type') &&
      !(request.body && request.body.toString() === '[object FormData]')
    ) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json'),
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });

    // return next.handle(request);
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => this.handleResponse(request, event),
        (error: Error) => this.handleError(request, error)
      )
    );
  }

  private handleResponse(req: HttpRequest<any>, event: HttpEvent<any>) {
    if (event instanceof HttpResponse) {
      if (event.status !== 200) {
        this.handleError(req, event);
      }
    }
  }

  private handleError(req: HttpRequest<any>, error: any) {
    console.log('[' + error.status + '] ' + req.method + ': ' + req.url);
    console.log(error);

    if (error.name === 'HttpErrorResponse' && error.error.error) {
      if (error.error.error.msg) {
        const status: number = error.status;

        if ([401, 404].indexOf(status) >= 0) {
          this.notifier.info(error.error.error.msg);
        } else if ([400, 403].indexOf(status) >= 0) {
          this.notifier.warning(error.error.error.msg);
        } else {
          this.notifier.error(error.error.error.msg);
        }
      }
    }
  }

  private resolveColor(status: number) {
    let color = 'error';

    switch (status) {
      case 401:
        color = 'info';
        break;
      case 400:
      case 403:
      case 404:
        color = 'warning';
        break;
      case 500:
        color = 'error';
        break;
    }
    return color;
  }

  private resolveMessage(status: number) {
    let msg = 'Une erreur inconnue est survenue. Veuillez réessayer...';

    switch (status) {
      case 400:
      case 422:
        msg = 'Veuillez remplir tout les champs obligatoires !';
        break;
      case 401:
      case 403:
        msg = "Vous n'avez pas la permission de faire cela !";
        break;
      case 404:
        msg = "L'élément auquel vous tentez d'accéder n'existe plus !";
        break;
    }

    return msg;
  }
}
