<div class="breadcrumb">
  <div class="head">
    <h1>{{ title }}</h1>
  </div>
  <div class="map">
    <ol>
      <li>
        <a routerLink="/">
          <i class="fa fa-home"></i>
        </a>
      </li>
      <ng-container *ngIf="parent">
        >
        <li class="">
          <a (click)="gotoParent()">{{ parent }}</a>
        </li>
      </ng-container>
      >
      <li class="active">{{ title }}</li>
    </ol>
  </div>
</div>
