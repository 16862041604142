import { environment } from 'src/environments/environment';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../../core/services/auth.service';
import { SEOService } from '../../core/services/seo.service';

@Component({
  selector: 'ui-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
})
export class LoginComponent implements OnInit {
  have_timeout = false;
  env_app_name = environment.app_name;

  constructor(
    private router: Router,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private seo: SEOService
  ) {}

  ngOnInit(): void {
    if (this.auth.isAuthenticated()) {
      this.router.navigate(['/dashboards']);
    }

    this.seo.updateTitle('Se connecter');
    this.seo.injectCanonical();

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['timeout']) {
        // this.notifier.warning('Veuillez vous reconnecter !');
      }
      if (params['token']) {
        this.auth.setToken(decodeURIComponent(params['token']));
        this.router.navigate(['/dashboards']);
      }
    });
  }

  getDiscordLink(): string {
    return environment.api + '/auth/discord';
  }
  getReturnLink(): string {
    return environment.login_back;
  }
}
