import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';

import { MediaInterface, MediaService } from '../media.service';

@Component({
  selector: 'app-songslist-showcase-button',
  template: `
    <button
      (click)="toggle()"
      [clrLoading]="loading"
      class="btn btn-star btn-icon"
      title="Mis en avant"
      *ngIf="media.is_showcased"
    >
      <i class="fa fa-star"></i>
    </button>
    <button
      (click)="toggle()"
      [clrLoading]="loading"
      class="btn btn-outline btn-icon"
      title="Non mis en avant"
      *ngIf="!media.is_showcased"
    >
      <i class="fa fa-star-o"></i>
    </button>
  `,
  styles: [],
})
export class SongsListShowcaseButtonComponent implements OnInit {
  @Input() media!: MediaInterface;
  @Output() changed = new EventEmitter<MediaInterface>();
  loading = ClrLoadingState.DEFAULT;

  constructor(private service: MediaService) {}

  ngOnInit(): void {}

  toggle(): void {
    this.loading = ClrLoadingState.LOADING;
    this.service
      .showcase(this.media.uuid, this.media.is_showcased ? false : true)
      .then(
        () => {
          this.media.is_showcased = this.media.is_showcased ? false : true;
          this.changed.emit(this.media);
          this.loading = ClrLoadingState.DEFAULT;
        },
        (err) => {
          this.loading = ClrLoadingState.DEFAULT;
        }
      );
  }
}
