<clr-vertical-nav>
  <label class="nav-header"> Ordre de tri </label>
  <a
    clrVerticalNavLink
    (click)="setOrder('DDESC')"
    [class.active]="getOrder() == 'DDESC'"
    >Date d'ajout <em>(plus récent d'abord)</em></a
  >
  <a
    clrVerticalNavLink
    (click)="setOrder('DASC')"
    [class.active]="getOrder() == 'DASC'"
    >Date d'ajout <em>(plus ancien d'abord)</em></a
  >
  <a
    clrVerticalNavLink
    (click)="setOrder('NASC')"
    [class.active]="getOrder() == 'NASC'"
    >Nom <em>(ordre alphabétique)</em></a
  >
  <a
    clrVerticalNavLink
    (click)="setOrder('NDESC')"
    [class.active]="getOrder() == 'NDESC'"
    >Nom <em>(ordre anti-alphabétique)</em></a
  >
  <div class="nav-divider"></div>
  <label class="nav-header"> Catégorie </label>
  <a
    clrVerticalNavLink
    (click)="setCategory(genre.uuid)"
    [class.active]="getCategory() == genre.uuid"
    *ngFor="let genre of genres"
    >{{ genre.name }}</a
  >
</clr-vertical-nav>

<div class="content-area">
  <div class="head">
    <ui-breadcrumb
      title="Mes tubes"
      parent="Sons"
      parentUrl="songs/list"
    ></ui-breadcrumb>

    <div class="btns">
      <button (click)="lgOpen = true" class="btn btn-primary">
        <i class="fa fa-plus"></i> Ajouter
      </button>
    </div>
  </div>

  <table class="table">
    <thead>
      <tr>
        <th>Pochette</th>
        <th>Nom</th>
        <th>Genres</th>
        <th>Sources</th>
        <th *ngIf="moderation_mode">Téléchargement</th>
        <th>Visible</th>
        <th>Mis en avant</th>
        <th>Date d'ajout</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loading">
        <td colspan="8">Chargement en cours...</td>
      </tr>

      <tr *ngIf="!loading && medium.length < 1">
        <td colspan="8">
          Aucun tube trouvé.
          <a *ngIf="!moderation_mode" (click)="lgOpen = true"
            >Pourquoi ne pas en ajouter un ?</a
          >
        </td>
      </tr>

      <ng-container *ngIf="!loading">
        <tr *ngFor="let media of medium; let i = index">
          <td>
            <img
              *ngIf="media.cover"
              class="cover"
              width="75"
              [src]="fileService.link(getCover(media))"
              alt="Pochette de {{ media.name }}"
            />
            <img
              *ngIf="!media.cover"
              class="cover"
              width="75"
              src="/assets/img/cover_default.png"
              alt="Pochette de {{ media.name }}"
            />
          </td>
          <td>
            <strong>{{ media.name }}</strong>
            <br />par
            <a
              href="{{ seo.getWebsiteEndpoint() }}/artistes/{{
                media.owner.pseudo
              }}"
              target="_blank"
              >{{ media.owner.pseudo }}</a
            >
            <i class="fa fa-external-link"></i>
          </td>
          <td>
            <span
              class="badge"
              *ngFor="let genre of getGenres(media); let y = index"
            >
              {{ genre.name }}
            </span>
          </td>
          <td>
            <a
              class="badge badge-purple"
              href="{{ media.link_soundcloud }}"
              *ngIf="media.link_soundcloud"
            >
              Soundcloud <i class="fa fa-soundcloud"></i>
            </a>
            <a
              class="badge badge-purple"
              href="{{ media.link_spotify }}"
              *ngIf="media.link_spotify"
            >
              Spotify <i class="fa fa-spotify"></i>
            </a>
            <a
              class="badge badge-purple"
              href="{{ media.link_deezer }}"
              *ngIf="media.link_deezer"
            >
              Deezer <i class="fa fa-deezer"></i>
            </a>
            <a
              class="badge badge-purple"
              href="{{ media.link_youtube }}"
              *ngIf="media.link_youtube"
            >
              YouTube <i class="fa fa-youtube"></i>
            </a>
          </td>
          <td *ngIf="moderation_mode">
            <songs-download-button
              [media]="media"
              *ngIf="media.link_youtube || media.link_soundcloud"
            ></songs-download-button>
          </td>
          <td>
            <app-songslist-visibility-button
              [media]="media"
            ></app-songslist-visibility-button>
          </td>
          <td>
            <app-songslist-showcase-button
              (changed)="onShowcaseChange($event)"
              [media]="media"
            >
            </app-songslist-showcase-button>
          </td>
          <td>
            <span class="badge">{{
              media.created_at * 1000 | date : "short"
            }}</span>
          </td>
          <td>
            <div class="btn-group" role="group">
              <button class="btn btn-primary btn-icon" (click)="edit(media)">
                <i class="fa fa-pencil"></i>
              </button>
              <button
                class="btn btn-danger btn-icon"
                (click)="confirmDeletion(media.uuid)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <ui-pager
    #pager
    [pageMax]="pageMax"
    (next)="on_next()"
    (previous)="on_previous()"
  ></ui-pager>
</div>

<clr-wizard
  #wizardlg
  [(clrWizardOpen)]="lgOpen"
  clrWizardSize="lg"
  (clrWizardOnFinish)="doFinish()"
  (clrWizardOnCancel)="doCancel()"
>
  <clr-wizard-title>Nouveau son</clr-wizard-title>

  <clr-wizard-button [type]="'cancel'">Annuler</clr-wizard-button>
  <clr-wizard-button [type]="'previous'">Retour</clr-wizard-button>
  <clr-wizard-button [type]="'next'">Suivant</clr-wizard-button>
  <clr-wizard-button [type]="'finish'">Terminer</clr-wizard-button>

  <clr-wizard-page [clrWizardPageNextDisabled]="!media.name || !media.genres">
    <ng-template clrPageTitle>Informations de base</ng-template>
    <form clrForm>
      <clr-input-container>
        <label for="InputName" class="clr-required-mark">Nom du titre</label>
        <input
          clrInput
          placeholder="Mon super tube"
          id="InputName"
          required
          name="name"
          [(ngModel)]="media.name"
        />
        <clr-control-error>Ce champs est requis.</clr-control-error>
      </clr-input-container>

      <clr-textarea-container>
        <label for="InputDescription">Description du titre</label>
        <textarea
          rows="5"
          clrTextarea
          [(ngModel)]="media.description"
          name="description"
          id="InputDescription"
        ></textarea>
        <clr-control-helper
          >Rédigez quelques mots à propos de votre morceau</clr-control-helper
        >
      </clr-textarea-container>

      <clr-checkbox-container>
        <label class="clr-required-mark">Genres musicaux</label>
        <clr-checkbox-wrapper *ngFor="let genre of genres">
          <input
            type="checkbox"
            clrCheckbox
            value="{{ genre.uuid }}"
            name="categories"
            (change)="toggleGenre(genre)"
            [checked]="isChecked(genre.uuid)"
          />
          <label>{{ genre.name }}</label>
        </clr-checkbox-wrapper>
        <clr-control-error
          >Veuillez sélectionner au moins un genre musical.</clr-control-error
        >
      </clr-checkbox-container>
    </form>
  </clr-wizard-page>

  <clr-wizard-page
    [clrWizardPageNextDisabled]="
      !media.link_youtube &&
      !media.link_soundcloud &&
      !media.link_spotify &&
      !media.link_deezer &&
      !media.link_itunes &&
      !media.link_bandcamp &&
      !media.link_tidal
    "
  >
    <ng-template clrPageTitle>Sources</ng-template>
    <form clrForm>
      <clr-input-container>
        <label>YouTube</label>
        <input
          clrInput
          [(ngModel)]="media.link_youtube"
          placeholder="https://www.youtube.com/watch?v=ID_VIDEO"
          name="social_youtube"
        />
      </clr-input-container>

      <clr-input-container>
        <label>Soundcloud</label>
        <input
          clrInput
          [(ngModel)]="media.link_soundcloud"
          placeholder="https://soundcloud.com/ID_UTILISATEUR/ID_MUSIQUE"
          name="social_soundcloud"
        />
      </clr-input-container>

      <clr-input-container>
        <label>Spotify</label>
        <input
          clrInput
          [(ngModel)]="media.link_spotify"
          placeholder="https://open.spotify.com/track/ID_MUSIQUE"
          name="social_spotify"
        />
      </clr-input-container>

      <clr-input-container>
        <label>Deezer</label>
        <input
          clrInput
          [(ngModel)]="media.link_deezer"
          placeholder="https://www.deezer.com/fr/track/ID_MUSIQUE"
          name="social_deezer"
        />
      </clr-input-container>

      <clr-input-container>
        <label>iTunes</label>
        <input
          clrInput
          [(ngModel)]="media.link_itunes"
          placeholder="https://music.apple.com/us/album/NOM_DE_L'ALBUM/ID_MUSIQUE"
          name="social_itunes"
        />
      </clr-input-container>

      <clr-input-container>
        <label>Bandcamp</label>
        <input
          clrInput
          [(ngModel)]="media.link_bandcamp"
          placeholder="https:/NOM_UTILISATEUR.bandcamp.com/track/ID_MUSIQUE"
          name="social_bandcamp"
        />
      </clr-input-container>

      <clr-input-container>
        <label>Tidal</label>
        <input
          clrInput
          [(ngModel)]="media.link_tidal"
          placeholder="https://tidal.com/browse/track/ID_MUSIQUE"
          name="social_tidal"
        />
      </clr-input-container>
    </form>
  </clr-wizard-page>

  <clr-wizard-page>
    <ng-template clrPageTitle>Pochette</ng-template>

    <form clrForm>
      <clr-input-container>
        <label>Image</label>
        <input
          clrInput
          #cover
          type="file"
          (change)="uploadCover($event)"
          multiple="false"
          accept="image/jpeg,image/png"
          [disabled]="loading"
        />
      </clr-input-container>

      <img
        [src]="fileService.link(getCover(media))"
        alt="Cover de {{ media.name }}"
        class="cover"
        height="450"
        width="450"
        *ngIf="media.cover"
      />
    </form>
  </clr-wizard-page>
</clr-wizard>

<clr-modal [(clrModalOpen)]="dlOpen">
  <h3 class="modal-title">Confirmer la suppression</h3>
  <div class="modal-body">
    <p>
      Etes-vous sûr de vouloir supprimer ce titre ?
      <strong>Vous ne pourrez pas le récupérer par la suite !</strong>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="dlOpen = false">
      Annuler
    </button>
    <button type="button" class="btn btn-primary" (click)="delete()">
      Confirmer
    </button>
  </div>
</clr-modal>
