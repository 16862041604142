import '@cds/core/icon/register.js';

import { ArtistInterface } from 'src/app/pages/users/artist.service';
import { environment } from 'src/environments/environment';

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('avatar') avatar_ref!: ElementRef;
  avatar: string = '/assets/img/avatar_default.png';
  opened = false;

  env_app_name = environment.app_name;

  constructor(public auth: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.auth.loaded.subscribe((artist: ArtistInterface) => {
      if (artist.avatar) {
        this.avatar_ref.nativeElement.src =
          environment.api + `/files/${artist.avatar.uuid}`;
      }
    });
  }

  toggle() {
    this.opened = !this.opened;
  }

  goto(name: string) {
    this.router.navigate([`${name}`]);
  }

  getAvatar(): string {
    return this.avatar;
  }

  getAttribute(name: string): any {
    const profile = this.auth.getProfile();
    if (!profile) return false;
    // @ts-ignore
    return profile[name];
  }

  disconnect($event: Event) {
    this.auth.disconnect();
  }
}
