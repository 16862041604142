import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from '../../../environments/environment';
import {
  ArtistInterface,
  ArtistService,
} from '../../pages/users/artist.service';
import { ApiOutputInterface } from '../interfaces/api.interface';

export interface ConnectionInterface {
  email: string;
  ip: string;
  success: boolean;
  uas?: string;
  owner?: ArtistInterface;
  created_at: string;
}

export interface TokenInterface {
  uuid: string;
  token?: string;
  ip: string;
  uas?: string;
  owner?: ArtistInterface;
  created_at: string;
}

interface ConnectionsHttpInterface extends ApiOutputInterface {
  connections: Array<ConnectionInterface>;
  pages: number;
}

interface TokensHttpInterface extends ApiOutputInterface {
  tokens: Array<TokenInterface>;
}

interface AuthHttpInterface extends ApiOutputInterface {
  token: string;
}

@Injectable()
export class AuthService {
  private profile: ArtistInterface = {} as ArtistInterface;
  @Output() loaded = new EventEmitter<ArtistInterface>();

  constructor(
    private router: Router,
    public jwtHelper: JwtHelperService,
    private http: HttpClient,
    private service: ArtistService
  ) {
    const token = localStorage.getItem('APP_TOKEN');

    if (token && this.isAuthenticated()) {
      const decoded_token = this.jwtHelper.decodeToken(token);

      // Retrieve profile
      this.service.fetch(decoded_token.data.uuid).then((profile) => {
        this.profile = profile;
        this.loaded.emit(profile);
      });
    }
  }

  public getProfile(): ArtistInterface {
    if (!this.isAuthenticated()) {
      throw new Error('Not Authenticated');
    }
    return this.profile;
  }

  public setProfile(profile: ArtistInterface): void {
    if (!this.isAuthenticated()) {
      throw new Error('Not Authenticated');
    }
    this.profile = profile;
  }

  public getPower(): number {
    const token: string | null = localStorage.getItem('APP_TOKEN');
    if (!token) return 0;
    const decoded_token = this.jwtHelper.decodeToken(token);
    return decoded_token.data.power;
  }

  public getUuid(): string {
    const token: string | null = localStorage.getItem('APP_TOKEN');
    if (!token) return '';
    const decoded_token = this.jwtHelper.decodeToken(token);
    return decoded_token.data.uuid;
  }

  public setToken(token: string) {
    localStorage.setItem('APP_TOKEN', token);
    const decoded_token = this.jwtHelper.decodeToken(token);

    // Retrieve profile
    this.service.fetch(decoded_token.data.uuid).then((profile) => {
      this.profile = profile;
      this.loaded.emit(profile);
    });
  }

  public isAuthenticated(): boolean {
    const token: string | null = localStorage.getItem('APP_TOKEN');
    if (!token) return false;
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }

  public doesTokenExist(): boolean {
    return localStorage.getItem('APP_TOKEN') !== undefined;
  }

  public disconnect(): void {
    localStorage.removeItem('APP_TOKEN');
    this.router.navigate(['/login']);
  }

  public signin(email: string, password: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http
        .post<AuthHttpInterface>(environment.api + '/auth/signin', {
          email: email,
          password: password,
        })
        .subscribe(
          (output: AuthHttpInterface) => {
            return resolve(output.token);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  public connections(
    page: number = 1,
    nb: number = 10,
    all: boolean = false
  ): Promise<ConnectionsHttpInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .get<ConnectionsHttpInterface>(environment.api + '/auth/connections', {
          params: {
            page: page.toString(),
            nb: nb.toString(),
            all: all ? 'true' : 'false',
          },
        })
        .subscribe(
          (output: ConnectionsHttpInterface) => {
            return resolve(output);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  public tokens(all: boolean = false): Promise<Array<TokenInterface>> {
    return new Promise((resolve, reject) => {
      this.http
        .get<TokensHttpInterface>(environment.api + '/auth/tokens', {
          params: { all: all ? 'true' : 'false' },
        })
        .subscribe(
          (output: TokensHttpInterface) => {
            return resolve(output.tokens);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }
}
