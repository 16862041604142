<div class="dropdown bottom-right" [class.open]="opened">
  <button
    class="dropdown-toggle btn-icon {{ getClass() }}"
    [clrLoading]="loading"
    [disabled]="isDisabled()"
    (click)="opened = !opened"
  >
    <i class="fa fa-cloud-download" *ngIf="!wasDownloaded()"></i>
    <i class="fa fa-check" *ngIf="wasDownloaded()"></i>
    <i class="fa fa-angle-down"></i>
  </button>
  <div class="dropdown-menu" *ngIf="!wasDownloaded()">
    <h4 class="dropdown-header">Source</h4>
    <div
      class="dropdown-item"
      (click)="download('youtube')"
      *ngIf="showYoutube()"
    >
      YouTube <i class="fa fa-youtube"></i>
    </div>
    <div
      class="dropdown-item"
      (click)="download('soundcloud')"
      *ngIf="showSoundcloud()"
    >
      Soundcloud <i class="fa fa-soundcloud"></i>
    </div>
  </div>
  <div class="dropdown-menu" *ngIf="wasDownloaded()">
    <div class="dropdown-item" (click)="delete()">
      Annuler le téléchargement
    </div>
  </div>
</div>
