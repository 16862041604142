<!-- <div class="login-box">
  <div class="card card-outline card-primary">
    <div class="card-header text-center">
      <img
        class="logo"
        src="/assets/img/logo.png"
        alt="Logo de TrackConnection"
      />
    </div>
    <div class="card-body">
      <p class="login-box-msg">
        Connectez-vous pour commencer la gestion de vos tubes.
      </p>

      <p class="mb-1">
        <a href="{{ getReturnLink() }}" class="btn btn-primary">
          <i class="fa fa-arrow-left"></i>
        </a>
        <a href="{{ getDiscordLink() }}" class="btn btn-discord">
          <i class="fa fa-discord"></i> avec Discord
        </a>
      </p>
    </div>
  </div>
</div> -->

<div class="login-wrapper">
  <form class="login">
    <section class="title">
      <h3 class="welcome">Welcome to</h3>
      {{ env_app_name }}
      <h5 class="hint">
        Connectez-vous pour commencer la gestion de vos tubes.
      </h5>
    </section>
    <div class="login-group">
      <a href="{{ getDiscordLink() }}" class="btn btn-discord">
        <i class="fa fa-discord"></i> avec Discord
      </a>
    </div>
  </form>
</div>
